import React from 'react'
import { ProductCard } from './product-card'
import { ProductCardOrange } from './product-card-orange'
import { Link } from 'gatsby'

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export const ProductListing = ({ more, title, products = [] }) => {
  return (
    <>
      <ul className='flex flex-row flex-wrap'>
        {products.map((product, productIndex) => (
          <li className='min-w-1/2'>
            <ProductCard
              key={product.id}
              eager={productIndex === 0}
              product={product}
            />
          </li>
        ))}
        {products.map((product, productIndex) => (
          <li className='min-w-1/2'>
            <ProductCard
              key={product.id}
              eager={productIndex === 0}
              product={product}
            />
          </li>
        ))}
        <Link as={`li`} to={more} />
      </ul>
    </>
  )
}

/*
  <ul className='grid grid-rows-3 grid-cols-2 gap-2 mx-2'>
        <h3 className='row-span-1 text-black'>{title}</h3>
        {products.map((product, productIndex) => (
          <li className='row-span-3'>
            <ProductCardOrange
              key={product.id}
              eager={productIndex === 0}
              product={product}
            />
          </li>
        ))}
        <Link as={`li`} to={more} />
      </ul>
      */
