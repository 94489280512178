import * as React from 'react'
import { Link } from 'gatsby'
import { classNames } from '@/utils/class-names'

// Pay attention to this part in particular:
// We use `JSX.IntrinsicElements` to refer to a native element
// And then we augment it with the properties we want to add
export type MoreButtonProps = JSX.IntrinsicElements['a'] & {
  readonly className?: string
  readonly to: string
}

// Safe Anchor
export const MoreButton: React.FC<MoreButtonProps> = ({ className, to }) => (
  <div className={classNames(className, 'p-4')}>
    <Link to={to} />
  </div>
)
