import * as React from 'react'
import { graphql } from 'gatsby'
import { LayoutTailwindDefault } from '@/layouts/layout-tailwind-default'
import { ProductListing } from '@/components/tailwind/product-listing'
import { Seo } from '@/components/tailwind/seo'
import slugify from '@sindresorhus/slugify'
import { MoreButton } from '@/components/tailwind/more-button'

export default function Products ({
  data: { products },
  pageContext: { vendor },
}) {
  return (
    <LayoutTailwindDefault>
      <Seo title={`${vendor} products`} />
      <h1>{vendor}</h1>
      <ProductListing products={products.nodes} />
      {products.pageInfo.hasNextPage && (
        <MoreButton to={`/search?v=${slugify(vendor)}#more`}>
          More Products
        </MoreButton>
      )}
    </LayoutTailwindDefault>
  )
}

export const query = graphql`
  query($vendor: String!) {
    products: allShopifyProduct(
      filter: { vendor: { eq: $vendor } }
      sort: { fields: publishedAt, order: DESC }
      limit: 24
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
