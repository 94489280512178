import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'
import { formatPrice } from '@/utils/format-price'
import isArray from 'lodash/isArray'
import { useProductCard } from '@/hooks/use-product'

export const ProductCard = ({ product, eager }) => {
  const {
    title,
    hasImage,
    thumb,
    vendor,
    price,
    defaultImageHeight,
    defaultImageWidth,
  } = useProductCard(product)

  return (
    <div className={`shadow-xl`}>
      {hasImage ? (
        <GatsbyImage
          className='relative w-40'
          //className='w-full h-full object-center object-cover group-hover:opacity-75'
          alt={thumb.altText ?? title}
          image={thumb.gatsbyImageData}
          loading={eager ? 'eager' : 'lazy'}
        />
      ) : (
        <div
          style={{
            height: defaultImageHeight,
            width: defaultImageWidth,
          }}
        />
      )}
      <div>
        <h2 className='mt-5 text-base font-semibold uppercase tracking-wider'>
          {title}
        </h2>

        <p className='mt-0 text-3xl font-extrabold uppercase tracking-tight sm:text-xl sm:mt-2 text-neutral-700'>
          {price}
        </p>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
    slug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    )
    media {
      ... on ShopifyMediaImage {
        id
        alt
        image {
          width
          height
          originalSrc
        }
      }
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    vendor
  }
`
